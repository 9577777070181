<template>
  <div class="page-container">
    <div class="page-title">{{ $t("pages.profile.pageTitle") }}</div>
    <div class="personal-container page-inner-wrap">
      <div class="personal-phone">
        <div class="personal-phone-num">{{phoneNumber}}</div>
        <button class="btn btn-small btn-medium btn-primary" @click="userLogout"><span class='btn--text'>Выйти</span></button>
      </div>
  </div>
    <div class="page-withScoll">
      <div class="scroll-container scroll-container-withHeader">
      <winMonthPromoBanner v-for="promoItem in promocodeMonthly" :promoItem="promoItem"></winMonthPromoBanner>
      <h2 class="profile-title">Общая статистика по играм</h2>
      <ul class="profile-static-list">
        <li class="profile-static-card">
          <div class="profile-static-img">
            <img v-if="darkTheme" src="@/assets/img/game-rebus-dark.svg" alt="Статистика по ребусам" width="60" height="77">
              <img v-else src="@/assets/img/game-rebus.svg" alt="Статистика по ребусам" width="60" height="77">
          </div>
          <div>
            <p class="profile-static-title ">Ребусы</p>
            <div class="profile-static-info">
              <span>Отгадано: </span>
              <span class="profile-static-num">{{userInfo.games.rebus.qtySuccess}}</span>
            </div>
          </div>
        </li>
        <li class="profile-static-card">
          <div class="profile-static-img">
           <img v-if="darkTheme" src="@/assets/img/game-pic2-dark.svg" alt="Статистика по воображалкам" width="60" height="77">
            <img v-else src="@/assets/img/game-pic2.svg" alt="Статистика по воображалкам" width="60" height="77">
          </div>
          <div>
            <p class="profile-static-title ">Воображалка</p>
            <div class="profile-static-info">
              <span>Отгадано: </span>
              <span class="profile-static-num">{{userInfo.games.pic2.qtySuccess}}</span>
            </div>
          </div>
        </li>
        <li class="profile-static-card">
          <div class="profile-static-img">
           <img v-if="darkTheme" src="@/assets/img/game-memorina-dark.svg" alt="Статистика по воображалкам" width="60" height="77">
            <img v-else src="@/assets/img/game-memorina.svg" alt="Статистика по воображалкам" width="60" height="77">
          </div>
          <div>
            <p class="profile-static-title ">Меморина</p>
            <div class="profile-static-info">
              <span>Отгадано: </span>
              <span class="profile-static-num">{{userInfo.games.memorina.qtySuccess}}</span>
            </div>
          </div>
        </li>
        <li class="profile-static-card">
          <div class="profile-static-img">
           <img v-if="darkTheme" src="@/assets/img/game-lines-dark.svg" alt="Статистика по воображалкам" width="60" height="77">
            <img v-else src="@/assets/img/game-lines.svg" alt="Статистика по воображалкам" width="60" height="77">
          </div>
          <div>
            <p class="profile-static-title ">Игра цвета</p>
            <div class="profile-static-info">
              <span>Отгадано: </span>
              <span class="profile-static-num">{{userInfo.games.colors.qtySuccess}}</span>
            </div>
          </div>
        </li>
      </ul>
<!--      <RouterLink class="btn btn-primary" :to="{name: 'winners'}"><span class="btn&#45;&#45;text">Список победителей</span></RouterLink>-->
    </div>
    </div>



  </div>
</template>


<script>
import { useMainStore } from '@/store';
import { useUserStore } from '@/store/user';
import { useAuthStore } from '@/store/auth';
import { RouterLink} from 'vue-router'
import { mapState, mapActions } from 'pinia';
import Modal from '@/components/modals/Modal.vue'
import winMonthPromoBanner from '@/components/promo/winMonthPromoBanner.vue'


export default {
  components: {
    Modal,
    winMonthPromoBanner,
    RouterLink
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapState(useMainStore, ['darkTheme']),
    ...mapState(useUserStore, ['userInfo', 'phone']),
    phoneNumber () {
      if (this.userInfo.msisdn) {
        const msisdn = this.userInfo.msisdn + '';
        return `+${msisdn.substring(0,1)} (${msisdn.substring(1,4)}) ${msisdn.substring(4,7)}-${msisdn.substring(7,9)}-${msisdn.substring(9,11)}`;
      }
      return '';
    },
    promocodeMonthly () {
      return this.userInfo.promocodeMonthly;
    },
  },
  methods: {
    ...mapActions(useAuthStore, ['logout']),
    modalClose() {
      this.showModal = false;
    },
    userLogout() {
      localStorage.removeItem('force_auth_ts');
      this.logout();
    }
  }
};
</script>

